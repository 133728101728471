

.uppy-Dashboard-inner, .uppy-Dashboard-AddFiles { border: none!important; }
.uppy-Dashboard-innerWrap { border: 2px dashed #c6cdd4; background: url(https://msp.serviceo.me/assets/img/upload-img.jpg) center top/contain no-repeat #fff;}


.uppy-Dashboard-note { font-size: 16px!important; color: #445b78!important; background: #eceef1; padding: 5px 18px !important; }
.formTitle { color: #445b78; font-weight: 600; font-size: 24px; margin: 10px 0 14px; } 
.customButton { margin-bottom: 1em;}
.customButton button { font-size: 16px; height: 42px; font-weight: 600; padding: 4px 42px; border-radius: 6px;}

.uploadPopUp .ant-upload  { border: 2px dashed #c6cdd4;
    background: url(https://msp.serviceo.me/assets/img/upload-img.jpg) center top/contain no-repeat #fff; min-height: 15em;
     }

.uploadPopUp .ant-upload p.ant-upload-text { font-weight: 600; margin-top: 1.5em!important;}
.ant-modal-content { min-height: 29em!important; }
.ant-modal-body { height: 23em!important;}

