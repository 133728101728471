.avater {
    padding: 0, 1rem;
    border: 1px solid #fff;

}

.avtMenuwidth {
    margin-top: 22px !important;
    width: 300px !important
}

.ant-dropdown-menu-item {
    padding: .71rem !important;
    border-bottom: 2px solid #eeee !important;
}
.ant-dropdown-menu-title-content {
    word-break: break-all !important;
}
.ant-dropdown-menu-title-content > a {
    text-transform: capitalize;
}
.lowerCase{
    text-transform: lowercase; 
}