body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  background: #fff;
}

/* .ant-page-header-heading-title {
  color: #1E85DF !important;
} */
.ant-divider-horizontal {
 margin: 12px 0px !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888873; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(160, 160, 160); 
}

button.CustomPrimBtn { font-size: 16px;
  height: 42px;
  font-weight: 600;
  padding: 4px 42px;
  border-radius: 6px;
 }

 button.CustomBtnOutline {font-size: 16px;
  height: 42px;
  font-weight: 600;
  padding: 4px 42px;
  border-radius: 6px;
 }