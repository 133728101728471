.msg-body {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 20px;
}

.msgRow { width: 100%; clear: both; }
.msg-view-card { padding-top: 2em!important; background: url(../../../../../assets/images/chat-bg.jpg) left top repeat!important; }

.msg-container {    
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;    
  float: left;
  max-width: 80%;
  position: relative;
}

.ant-image {
display: block !important;
}
.darker {
  float:right;
  padding: 10px;
  background: #1e85df;
  color: #fff;
}


.msg-container::after {
  content: "";
  clear: both;
  display: table;
}

.msg-container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

.darker img {
  float: right;
}

.msg-container img.right {
  float: right;
  margin-left: 20px;
  margin-right:0;
}

.msg-container p { font-size: 1.1em; font-weight: 500; }
.msg-container { border-radius: 6px 6px 6px 25px; }
.msg-container.darker { border-radius: 6px 6px 25px; position: relative; }
.msg-container.darker::before { content: "";
  position: absolute;
  z-index: 1;
  bottom: -2px;
  right: -8px;
  height: 24px;
  border-right: 20px solid #1e85df;
  border-bottom-left-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px); }

  .msg-container.darker::after { content: "";
    position: absolute;
    z-index: 1;
    bottom: -2px;
    right: -42px;
    width: 12px;
    height: 24px;
    background: #ebeff2;
    border-bottom-left-radius: 10px;
    -webkit-transform: translate(-30px, -2px);
    transform: translate(-30px, -2px); }
.msg-container .time { float: right; color: #93a1aa; }
.msg-container.darker .time { float: right; color: #fff; margin: 0 6px 0 0; }
.hideBox { opacity: 0; height: 0; transition: 0.3s all; }
.showBox { opacity: 1; min-height: 3.5em; transition: 0.3s all; }
.toggleBoxBtn { position: absolute!important; left: 50%; bottom: -1em; transform: translateX(-50%); }  
.msg-container.darker .sentTime { color: #fff; text-align: right; display: block; }
.msg-container.lighter .sentTime { color: #000;  text-align: right;  display: block; }

.msg-container.lighter:before { content: "";
  position: absolute;
  z-index: 3;
  bottom: -2px;
  left: 4px;
  width: 26px;
  height: 24px;
  background: #ebeff2;
  border-bottom-right-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
  transform: translate(-30px, -2px); }


  .msg-container.lighter:after { content: "";
    position: absolute;
    z-index: 2;
    bottom: -2px;
    left: -7px;
    height: 24px;
    border-left: 20px solid #fff;
    border-bottom-right-radius: 16px 14px;
    -webkit-transform: translate(0, -2px);
    transform: translate(0, -2px);}



.msg-list-card {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  background-color: #eeee !important;
}

.msg-list-container{
  border: 2px solid #dedede;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.headBodyWrap { height: calc(100vh - 105px); }
.msgDrawer .ant-drawer-content { overflow: hidden;}
.msg-view-card {
  height: calc(100vh - 214px);
  overflow-y: scroll; 
  display: flex;
flex-direction: column-reverse;  
}

.msg-view-card .ant-card {
  background-color: transparent !important;
}

.post-msg-card .ant-card-body {
  padding-left: 1px !important;
  padding-right: 1px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.post-msg-input .ant-input-compact-item {
  height: 50Px !important;
  border: none !important;
}

.post-msg-input .ant-btn-primary {
 margin-top: 5px !important;
 height: 42px !important;
 font-size: 16px;
 font-weight: bold;
}
.msg-header{
background: #fff !important;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0 0 !important;    
  margin-bottom: -1em !important;
  position: relative;
  z-index: 9;
}

.msg-header>.ant-card-body{
padding-top: 20px !important;
}

.headerMain { position: sticky; top: 0em; z-index: 99; }

.msgFooterWrap { padding: 1em 0 0 0; display: flex; width: 99%; justify-content: space-between; background: #fff; }
.msgFooterWrap .ant-btn-primary { width: 7em; margin-top: 0!important; border-radius: 6px; }
.msgFooterWrap .ant-btn-primary:hover { background: #0d7adf; }
.msgFooterWrap .ant-btn-primary .anticon { transition: 0.3s all; transform: translateX(0); }
.msgFooterWrap .ant-btn-primary:hover .anticon { transform: translateX(5px); transition: 0.3s all;}

/* New css for case messaging */
.msgContent.rightSide { float: right; max-width: 85%;  padding: 10px 15px 8px 15px; background: #1e85df;  border-radius: 10px; border: 1px solid #1e85df; position: relative; }
.msgContent.rightSide p.msg { font-size: 1em; padding: 0; margin: 1.3em 0 5px 0; color: #fff; font-weight: 400; }
.msgContent.rightSide .sentTime { color: #fff; text-align: right; padding: 0; display: block; font-size: 1em; font-weight: 400;}
.PerName { font-weight: 600; font-size: 1em; color: #637e9b; margin: -2.6em 0 0 0; color: #1e85df; }

.msgMain { margin-top: 2em!important; }
.msgContent.leftSide { float: left; position: relative; max-width: 85%;}
.msgContent.leftSide:before { position: absolute; content: ''; height: 75%; width: 4px; border-radius: 0 10px 10px 0; left: 0; top: 50%; background: #0354b1; transform: translateY(-50%); }
.msgContent.leftSide.caseGray { border-color: #6e7984; }
.msgContent.leftSide.caseGray::before { background: #6e7984;}
.msgContent.leftSide.caseGray .PerName { color: #6e7984;}
.msgContent.leftSide { padding: 10px 15px 8px 15px; background: #fff;  border-radius: 10px; border: 1px solid #0354b1; position: relative; }
.msgContent.leftSide p.msg { font-size: 1em; padding: 0; margin: 1.3em 0 5px 0; color: #0d1d30; font-weight: 500; }
.msgContent.leftSide .sentTime { color: #637e9b; text-align: right; padding: 0; display: block; font-size: 1em; font-weight: 400;}

.messageMainContainer { position: relative;}
.msgFooter {  width: 100%; height: 3.6em; position: absolute; bottom: 0em; z-index: 99; background: #fff; box-shadow: 0 -7px 5px 0px rgba(0, 0, 0, 0.05);}
.msgFooter .ant-col-24 { background: #fff;}
.msgFooterWrap .ant-form-item { width: 100%; }
.msgFooterWrap .ant-input-affix-wrapper { width: 96%; float: right; margin: 0 1% 0 0; height: 2.7em; padding: 0 0 0 1em; border: 1px solid #bbc3cb!important; border-radius: 4px;   }
.msgFooterWrap .ant-input-affix-wrapper input { padding: 1.2em 0.5em!important;}
.msgFooterWrap .ant-input-affix-wrapper input::placeholder { color: #a5a8ac; font-weight: 600; }
.msgFooterWrap .ant-input-prefix { margin-right: 1em; margin-left: -3.1em; }
.msgFooterWrap .ant-form-item-explain-error { margin: 0 0 0 3.6em; }
.msgFooterWrap textarea { padding: 0.7em 1em 0 1em!important; min-height: 43px!important; border: 1px solid #bbc3cb !important; border-radius: 4px; float: right;
margin: 0 1% 0 0.5%;font-size: 14px;
color: #0d1d30;
font-weight: 500; }
.msgFooterWrap textarea::placeholder { color: #a5a8ac; font-weight: 600; }
.anticon-plus { display: inline-block; margin: 0 0.6em 0 0.5em;}


.caseStatus { position: absolute;
top: -20px;
left: 50%;
min-width: 13em;
background: #f4f4f4;
border-radius: 10px;
z-index: 9999999;
transform: translateX(-50%);
padding: 0.3em 1em;
text-align: center; border: 1px solid #ccc;}

.ant-drawer-body { overflow: visible!important;}



@media only screen and (max-width: 1550px) {
  .msgFooterWrap .ant-input-affix-wrapper { width: 95%;}
} 

@media only screen and (max-width: 1200px) {
  .msgFooterWrap .ant-input-affix-wrapper { width: 94%;}
  .msgFooterWrap { width: 97%;}
} 

@media only screen and (max-width: 1000px) {
  .msgFooterWrap .ant-input-affix-wrapper { width: 91%;}
} 
.msgContent.leftSide a.msgDocLink { color: #0d1d30!important;}
.msgContent.leftSide a.msgDocLink:hover { color: #0d1d30!important;}
.msgContent.leftSide a.msgDocLink::after { background: #0d1d30!important;}
a.msgDocLink { position: relative; color: #fff; font-size: 1.2em; font-weight: 600; }
a.msgDocLink:hover { color: #fff; }
a.msgDocLink::after { content: ''; height: 1px; background: #fff; width: 0; transition: 0.3s all; position: absolute; bottom: -4px; left: 0; }
a.msgDocLink:hover:after { width: 100%; transition: 0.3s all;}
a.msgDocLink:hover .anticon-file-text { display: none;}
a.msgDocLink:hover .anticon-arrow-down { display: inline-block;}
a.msgDocLink .anticon-arrow-down { display: none;}
a.msgDocLink .anticon-arrow-down { animation: MoveUpDown 1s linear infinite;}

@keyframes MoveUpDown {

0%,
100% {
  transform: translateY(0);
}

50% {
  transform: translateY(-4px);
}

}

.readMoreBtn { background: none; border:0; color: #fff; cursor: pointer; font-weight: 600; padding: 0; margin: 0; }
.msgContent.leftSide .readMoreBtn { color: #1e85df!important;}
.readMoreBtn:hover { text-decoration: underline; }
.readMoreBtn svg { margin-right: 0.3em;}
p.isOpenCss { overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; line-clamp: 3; -webkit-box-orient: vertical; }
.caseOpen span.ant-typography { overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; line-clamp: 3; -webkit-box-orient: vertical; }
.caseDesWrapper .anticon { display: inline-block; margin: 0 0 0 0.3em!important; position: relative; top: 2px; }
span.caseReadMore { text-decoration: underline; margin: 0.3em 0 0 0; display: inline-block; font-size: 1em; color: #797979; font-weight: 600!important; cursor: pointer; }
span.caseReadMore:hover .anticon {animation: MoveUpDown 1s linear infinite;}
#Customer_Appointment_Date_Time_Schedule__c { font-weight: 600;}