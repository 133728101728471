.logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    /* background-image: '../../../assets/images/serviceo-logo-White.svg'; */
    /* background: rgba(255, 255, 255, 0.3); */
  }

  .app-logo {
    float: left;
    margin-right: 24px;
    margin-top: 7px;
  }

  .app-header {
    padding: 0px !important;
    width: 100% !important;
    z-index: 100 !important;
    right: 0px !important;
  }

  .ant-layout-header {
    background: #1e88e5 !important;
    height:60px!important;
  }
  
  .site-layout-background {
    background: #fff;
  }

  .right {
    display: flex;
    float: right;
    height: 100%;;
    margin-left: auto;
    overflow: hidden;
 }
  
  