 .site-layout-background {
    background: #f0f2f5 !important;
  }

  .site-layout-background  .ant-menu-vertical > .ant-menu-item {
    height: 56px !important;
    line-height: 23px !important;
    text-align: center !important;
    padding: 0px 5px  !important;
  }

  .site-layout-background  .ant-menu-item .anticon {
    font-size: 22px !important;
    margin-top: 5px !important;
  }

  .site-layout-background  .ant-menu {
     background: #f0f2f5 !important;
  }

  .site-layout-background .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
    font-weight: 600 !important;
  }

  .site-layout-background .ant-menu-item:hover {
    color: #1890ff;
    font-weight: 600;
  }